
























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import Sidebar from '@/components/left-sidebar.vue'; // @ is an alias to /src
import RSidebar from '@/components/right-sidebar.vue'; // @ is an alias to /src
import HRMService from "../../../services/hrm-service";
@Component({
    components: {
        Header,
        Sidebar,
        RSidebar
    },
})
export default class Employee extends Vue {
    @Prop({ type: String })
    id!: string;
    edit = true;
    salary: any = {};
    mounted() {
        if (this.id) {
            this.retrieve(this.id)

        } else {
            this.edit = false
        }
    }

    retrieve(id: string) {
        HRMService.get_salary(id)
            .then((response) => {
                let data = response.data;
                this.salary = data;
            })
            .catch((e) => {
                console.log(e);
            });
    }

}
